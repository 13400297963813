import { makeStyles } from "@mui/styles";
import AppErrorImg from "../assets/icons8-warning-96.png";
import useMediaQuery from "../helpers/media-query-handler";
import { chargeStyles } from "../utils/pages-styles";

const useStyles = makeStyles(() => ({
  maintenanceImg: (props) => props.maintenanceImg,
  bottomText: (props) => props.bottomText,
  subText: (props) => props.subText,
  maintenanceImg_768: (props) => props.maintenanceImg_768,
  main_div_768: (props) => props.app_error_main_div_768,
  main_div: (props) => props.app_error_main_div,
  sub_div: (props) => props.sub_div,
}));

function AppErrorComponent() {
  const classes = useStyles(chargeStyles());

  const isMob = useMediaQuery("(max-width: 450px)");

  return (
    <div className={isMob ? classes.main_div_768 : classes.main_div}>
      <div style={{ textAlign: "center" }}>
        <img src={AppErrorImg} alt="appErrorImg" />
      </div>
      <div className={`row ${classes.bottomText}`}>
        <h1>Something's wrong here...</h1>
        <span className={classes.subText}>Refresh the page and continue</span>
      </div>
    </div>
  );
}

export default AppErrorComponent;

import "../styles/main.scss";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";

import { ThemeProvider, createTheme } from "@mui/material/styles";

import HomeComponent from "./home-component";
import { ToastContainer } from "react-toastify";
import Loader from "../library/Loader/loader";

function App() {
  const { authorize } = useSelector((state) => state);

  return (
    <div>
      <ThemeProvider
        theme={createTheme({
          typography: {
            useNextVariants: true,
            fontFamily: "ProximaNovaRegular",
          },
        })}
      >
        <ToastContainer
          style={{ zIndex: 100000 }}
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Loader loader={authorize.loader} />
        <Routes>
          <Route path="/qaccess/v1/terminal/:token?" element={<HomeComponent />} />
          <Route path="/qa-access/v1/terminal/:token?" element={<HomeComponent />} />
          <Route path="/uaccess/v1/terminal/:token?" element={<HomeComponent />} />
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;

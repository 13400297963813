import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { paymentMethods, paymentModeTypes, transactionMode } from "../constants/constants";
import { checkAuthorize, createTransaction, setLoader } from "../services";
import { useDispatch } from "react-redux";
import { isEmpty } from "underscore";
import { chargeStyles } from "../utils/pages-styles";
import AuthorizationForm from "./components/authorization-form";
import { convertAscii, getPaymentModeType, getPricinType, priceUnit } from "../utils/app-utils";
import StripeForm from "./components/stripe-payment.component";

const useStyles = makeStyles((theme) => ({
  main_div: (props) => props.main_div,
  main_div_768: (props) => props.main_div_768,
  textfield: (props) => props.textfield,
  textfieldexp: (props) => props.textfieldexp,
}));

function UserPreferenceContainer({
  authorize,
  emailError,
  setEmailError,
  makeSessionCall,
  setPaymentPrepareDialog,
  setInstructionDialog,
}) {
  const [expanded, setExpanded] = useState("");
  const [preferences, setPreference] = useState([]);
  const [clientSecret, setClientSecret] = useState("");
  const [stripePromise, setStripePromise] = useState(null);
  const [isEmailExist, setIsEmailExist] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles(chargeStyles());
  const isMob = useMediaQuery("(max-width: 768px)");
  const isTab = useMediaQuery("(max-width: 945px)");
  const is768 = isMob || isTab;

  useEffect(() => {
    if (authorize.stationDetails.data && authorize.stationDetails.data.channels && !expanded) {
      let array = [];
      let guest_preference_exist = authorize.stationDetails.data.channels.find(
        (elem) => elem.chargeUserType === 1
      );
      let others_prefernce_exist = authorize.stationDetails.data.channels.filter(
        (elem) => elem.chargeUserType !== 1
      );

      let is_email_pref_exist = authorize.stationDetails.data.channels.find(
        (elem) => elem.chargeUserType === 1 || elem.chargeUserType === 0
      );
      setIsEmailExist(is_email_pref_exist ? true : false);
      if (guest_preference_exist && !isEmpty(guest_preference_exist.chargePriceDetails)) {
        array = [...array, { user: "GUEST", prices: guest_preference_exist.chargePriceDetails }];
      }
      if (!isEmpty(others_prefernce_exist)) {
        array = [...array, { user: "OTHERS" }];
      }
      setPreference(array);
      setExpanded(
        guest_preference_exist && !isEmpty(guest_preference_exist.chargePriceDetails)
          ? "GUEST"
          : "OTHERS"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorize.stationDetails]);

  useEffect(() => {
    //fetch PID
    if (process.env.REACT_APP_STIPE_KEY) {
      setStripePromise(loadStripe(process.env.REACT_APP_STIPE_KEY));
      if (authorize.stripeId?.data) {
        setClientSecret(authorize.stripeId?.data);
      }
    }
  }, [authorize.stripeId?.data]);

  const handleAuthSubmit = (values) => {
    setEmailError("");
    let params = {
      terminalCode: authorize.terminalDetails?.terminalCode,

      webId: values.webId,
      uid: values.email && convertAscii(values.email),
      connectorId: authorize.terminalDetails?.connectorId
        ? authorize.terminalDetails?.connectorId
        : 1,
    };
    dispatch(setLoader(true));
    dispatch(checkAuthorize(params));
  };

  const initiateTransaction = (email) => {
    let payment_mode = getPaymentModeType(authorize.stationDetails.data);
    let param = {
      chargingTerminalCode: authorize.terminalDetails.terminalCode,
      connectorId: authorize.terminalDetails.connectorId
        ? authorize.terminalDetails.connectorId
        : 1,
      setupTokenId: clientSecret,
      paymentGatewayType: paymentMethods.STRIPE,
      privacyPolicy: false,
      termsAndConditions: false,
      transactionMode: transactionMode.PAYMENT_GATEWAY,
      transactionType: "0",
      customerEmail: email,
      paymentModeType:
        payment_mode === "AUTOMATIC" ? paymentModeTypes.AUTOMATIC : paymentModeTypes.MANUAL,
    };
    dispatch(setLoader(true));
    dispatch(createTransaction(param));
  };

  const getPriceData = (price) => {
    let pricing_type = "";
    let price_data;
    if (!isEmpty(price)) {
      if (price.length >= 1) {
        pricing_type = getPricinType(price[0].pricingType, price[0].pricingStrategy);
        price_data = price[0]?.price;
      }
    }
    return `${priceUnit(authorize.stationDetails?.data?.currency)}${price_data}/ ${pricing_type} `;
  };

  return (
    <div className={is768 ? classes.main_div_768 : classes.main_div}>
      <div
        style={
          is768
            ? {
                width: "auto",
                display: "flex",
                flexDirection: "column",
              }
            : {
                width: "auto",
                display: "flex",
                flexDirection: "column",
              }
        }
      >
        <div style={{ textAlign: "center" }}>
          <div style={is768 ? { padding: "5% 10%" } : { padding: "0% 30%" }}>
            {preferences.map((user_pref) => {
              return (
                <Accordion
                  sx={{ marginBottom: "2%" }}
                  expanded={expanded === user_pref.user ? true : false}
                  onChange={() => setExpanded(user_pref.user)}
                >
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{ background: "#009934", color: "#FFFFFF" }}
                  >
                    <Typography
                      sx={{ fontSize: "18px", paddingRight: "8px", letterSpacing: "0.4px" }}
                    >
                      {user_pref.user === "GUEST" ? "Pay and Start" : "Enter Code to Start"}
                    </Typography>

                    {user_pref.user === "GUEST" && (
                      <Typography
                        sx={{ fontSize: "13px", letterSpacing: "0.5px", paddingTop: "6px" }}
                      >
                        {getPriceData(user_pref.prices)}
                      </Typography>
                    )}
                    <img
                      src={
                        "https://emaestro-svp-cms.s3.amazonaws.com/assets/a23c0ba2-7469-4418-b6cd-682802ae4d06.png"
                      }
                      style={{ width: "20px", color: "white", padding: "3px" }}
                      alt="Info icon"
                      onClick={(event) => {
                        event.stopPropagation();
                        setInstructionDialog(true);
                      }}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    {user_pref.user === "GUEST" ? (
                      clientSecret && stripePromise ? (
                        <>
                          <Elements stripe={stripePromise} options={{ clientSecret }}>
                            {getPaymentModeType(authorize.stationDetails?.data) === "MANUAL" && (
                              <Typography
                                sx={{ color: "#fe2712", fontSize: "13px", padding: "5px 0px" }}
                              >
                                {priceUnit(authorize.stationDetails.data?.currency)}
                                {authorize.stationDetails.data?.holdingAmount} hold will be applied.
                                Final billing after the session.
                              </Typography>
                            )}
                            <StripeForm
                              initiateTransaction={initiateTransaction}
                              authorize={authorize}
                              makeSessionCall={makeSessionCall}
                              setPaymentPrepareDialog={setPaymentPrepareDialog}
                            />
                          </Elements>
                        </>
                      ) : (
                        <div style={{ color: "#333333", opacity: "0.7", fontSize: "16px" }}>
                          Loading...
                        </div>
                      )
                    ) : (
                      <AuthorizationForm
                        handleAuthSubmit={(value) => handleAuthSubmit(value)}
                        classes={classes}
                        emailError={emailError}
                        isEmailExist={isEmailExist}
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        </div>
      </div>
      <Box sx={{ position: "absolute", paddingLeft: "10px" }}>
        <Typography sx={{ fontSize: "10px", color: "gray" }}>Version 1.0.4</Typography>
      </Box>
    </div>
  );
}

export default UserPreferenceContainer;

import "../styles/main.scss";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { Typography } from "@mui/material";
import { isEmpty } from "underscore";

import AppErrorComponent from "../app-error/app-error.component";
import {
  checkChargeSession,
  fetchClientToken,
  getPidStripe,
  getStationDetails,
  setLoader,
  setStoreData,
} from "../services/actions";
import { authConst, chargerStates, encryptConst, paymentModeTypes } from "../constants/constants";
import * as actionTypes from "../services/types";
import { failureCheck, successCheck } from "../utils/api-response-check";
import { fetchAccountId, getPaymentModeType, routeCheck } from "../utils/app-utils";
import useMediaQuery from "../helpers/media-query-handler";

import { decryptText } from "../helpers/encyption-handler";
import UserPreferenceContainer from "../user-preferences/user-preference.container";
import ConfirmationDialog from "../library/ConfirmationDialog/confimationDialog";
import {
  chargeDurationConfig,
  connectorErrorDialogueConfig,
  connectorInUseDialogueConfig,
  paymentConfirmDialogueConfig,
  stationErrorDialog,
  transactionErrorConfig,
} from "../utils/dialog-config-utils";
import DashboardContainer from "../charge-dashboard/dashboard.container";
import InstructionsDialogComponent from "./instructions-dialog.component";

function HomeComponent() {
  const [isAuthenticate, setIsAuthenticate] = useState(false);
  const [isError, setIsError] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [stationError, setStationError] = useState(false);
  const [paymentPrepareDialog, setPaymentPrepareDialog] = useState(false);
  const [transactionError, setTransactionError] = useState(false);
  const [durationDialog, setDurationDialog] = useState(false);
  const [connectorErrorDialog, setConnectorErrorDialog] = useState(false);
  const [instructionDialog, setInstructionDialog] = useState(false);
  const [connectorInUseDialog, setConnectorInUseDialog] = useState(false);
  const [stationLogo, setStationLogo] = useState("");
  const [stationData, setStationData] = useState({});
  const [hasTrigerPid, setHasTrigerPid] = useState(false);

  const { authorize } = useSelector((state) => state);
  const location = useLocation();
  const dispatch = useDispatch();
  const isMob = useMediaQuery("(max-width: 768px)");
  const isTab = useMediaQuery("(max-width: 945px)");
  const is768 = isMob || isTab;

  useEffect(() => {
    // trigger client token call
    dispatch(setLoader(true));
    dispatch(setStoreData(actionTypes.TERMINAL_DETAILS, {}));
    dispatch(setStoreData(actionTypes.TOKEN, null));
    let pathname_split = location.pathname.split("/");
    dispatch(setStoreData(actionTypes.ACCOUNT_ID, fetchAccountId(pathname_split[1])));
    dispatch(
      fetchClientToken(
        {
          grantType: "client_credentials",
          clientId: authConst.clientId,
          clientSecret: authConst.clientSecret,
        },
        fetchAccountId(pathname_split[1])
      )
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (authorize.stationDetails) {
      if (successCheck(authorize.stationDetails) && !isEmpty(authorize.stationDetails.data)) {
        if (!isEmpty(stationData) && !authorize.sessionStatus) {
          const entries1 = Object.entries(authorize.stationDetails.data?.channels);
          const entries2 = Object.entries(stationData);
          if (JSON.stringify(entries1) !== JSON.stringify(entries2)) {
            setIsError(true);
          }
        }
        setStationData(authorize.stationDetails.data?.channels);

        if (isEmpty(authorize.stationDetails.data?.channels)) {
          setStationError(true);
          setTransactionError(false);
          setInstructionDialog(false);
          setPaymentPrepareDialog(false);
        }
        handleChargerAvailability(authorize.stationDetails);
        let logo = JSON.parse(authorize.stationDetails?.data?.logo);
        setStationLogo(!isEmpty(logo) ? logo[0]?.logo_url : "");
        if (isEmpty(authorize.stripeId) && isEmpty(location.search) && !hasTrigerPid) {
          let payment_mode = getPaymentModeType(authorize.stationDetails.data);
          let stripeParams = {
            chargingTerminalCode: authorize.terminalDetails.terminalCode,
            connectorId: authorize.terminalDetails?.connectorId
              ? authorize.terminalDetails?.connectorId
              : 1,
            paymentGatewayType: "3",
            transactionMode: "1",
            transactionType: "0",
            paymentModeType:
              payment_mode === "AUTOMATIC" ? paymentModeTypes.AUTOMATIC : paymentModeTypes.MANUAL,
          };
          dispatch(getPidStripe(stripeParams));
          setHasTrigerPid(true);
        }
      } else if (failureCheck(authorize.stationDetails)) {
        setIsError(true);
        setInstructionDialog(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorize.stationDetails]);

  useEffect(() => {
    if (authorize.token && isEmpty(authorize.terminalDetails)) {
      dispatch(setLoader(false));
      window.scroll(0, 0);
      let pathname = location.pathname;

      if (!routeCheck(pathname)) {
        let pathname_split = pathname.split("/");
        if (pathname_split[4]) {
          const terminal_data = pathname_split[4].split(",");

          dispatch(
            setStoreData(actionTypes.TERMINAL_DETAILS, {
              terminalCode: terminal_data[0],
              connectorId: terminal_data[1],
            })
          );
          dispatch(getStationDetails(terminal_data[0]));
          if (location.search) {
            let decryptd_params = decryptText(
              location.search,
              encryptConst.secret,
              encryptConst.iv_key
            );
            let reservation_id = new URLSearchParams(decryptd_params).get("reservationId");
            let user_type = new URLSearchParams(decryptd_params).get("userType");
            if (reservation_id && user_type) {
              dispatch(setLoader(true));
              dispatch(setStoreData(actionTypes.CHARGE_STATUS_CHECK, true));
              dispatch(
                setStoreData(
                  actionTypes.USER_TYPE,
                  user_type === "1" ? "GUEST" : user_type === "0" ? "PREMIUM" : "POSTPAID"
                )
              );
              dispatch(
                setStoreData(actionTypes.AUTH_DETAILS, {
                  reservationId: reservation_id,
                  userType: user_type,
                })
              );
              setIsAuthenticate(true);
            }
          } else {
            setInstructionDialog(true);
          }
        } else {
          setIsError(true);
          setInstructionDialog(false);
        }
      } else {
        setIsError(true);
        setInstructionDialog(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorize.token, authorize.terminalDetails]);

  useEffect(() => {
    if (authorize.authoriseResponse) {
      if (successCheck(authorize.authoriseResponse)) {
        dispatch(setStoreData(actionTypes.AUTH_DETAILS, authorize.authoriseResponse.data));
        dispatch(setLoader(false));
        setIsAuthenticate(true);
        dispatch(
          setStoreData(
            actionTypes.USER_TYPE,
            authorize.authoriseResponse?.data?.userType === 0
              ? "PREMIUM"
              : authorize.authoriseResponse?.data?.userType === 1
              ? "GUEST"
              : "POSTPAID"
          )
        );
        if (authorize.authoriseResponse?.data?.userType === 1) {
          setDurationDialog(true);
        }
      } else if (failureCheck(authorize.authoriseResponse)) {
        if (authorize.authoriseResponse.status === 10540) {
          setEmailError("Email / Phone number is mandatory for this user");
        } else if (authorize.authoriseResponse.status === 10506) {
          setEmailError("Code is invalid");
        } else {
          if (authorize.authoriseResponse?.message) {
            setEmailError(authorize.authoriseResponse.message);
          } else {
            setIsError(true);
            setInstructionDialog(false);
          }
        }
        dispatch(setLoader(false));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorize.authoriseResponse]);

  useEffect(() => {
    if (!isEmpty(authorize.chargingSession) && !isEmpty(authorize.transactionInitData)) {
      if (authorize.chargingSession?.status === 0) {
        if (authorize.chargingSession.data?.paymentStatus === "SUCCESS") {
          setPaymentPrepareDialog(false);
          dispatch(setStoreData(actionTypes.CHARGING_SESSION, null));
          dispatch(setStoreData(actionTypes.USER_TYPE, "GUEST"));
          dispatch(
            setStoreData(actionTypes.AUTH_DETAILS, {
              reservationId: authorize.transactionInitData.data.userChargeSessionId,
              userType: 1,
            })
          );
          dispatch(setStoreData(actionTypes.MAKE_TRANSACTION, null));
          dispatch(setLoader(false));
          setIsAuthenticate(true);
        } else if (authorize.chargingSession.data?.paymentStatus === "FAILED") {
          setPaymentPrepareDialog(false);
          setTransactionError(true);
          dispatch(setStoreData(actionTypes.MAKE_TRANSACTION, null));
        } else {
          makeSessionCall();
        }
      } else if (authorize.chargingSession?.status !== 0) {
        setPaymentPrepareDialog(false);
      }
      dispatch(setStoreData(actionTypes.CHARGING_SESSION, null));
    }
  }, [authorize.chargingSession]);

  const handleChargerAvailability = (details) => {
    let state = details.data?.state;
    let isAvailable = chargerStates.find((elem) => elem === state);
    if ((!isAvailable && isAvailable !== 0) || state === 1) {
      if (state === 1) {
        if (!authorize.sessionStatus && !authorize.isChargeStatusCheck) {
          setConnectorInUseDialog(true);
          setConnectorErrorDialog(false);
        }
      } else {
        setConnectorErrorDialog(true);
        setConnectorInUseDialog(false);
        setInstructionDialog(false);
      }
    } else {
      setConnectorErrorDialog(false);
      setConnectorInUseDialog(false);
    }
    const timeInterval = 12000;
    let flag = true;
    const interval = setInterval(() => {
      if (flag) {
        dispatch(getStationDetails(authorize.terminalDetails?.terminalCode));
      }
      flag = false;
    }, timeInterval);
    return () => clearInterval(interval);
  };

  const makeSessionCall = () => {
    const timeInterval = 10000;
    let flag = true;
    const interval = setInterval(() => {
      if (flag) {
        dispatch(
          checkChargeSession({
            userType: 1,
            reservationId: authorize.transactionInitData.data.userChargeSessionId,
            terminalCode: authorize.terminalDetails?.terminalCode,
          })
        );
        flag = false;
      }
    }, timeInterval);
    return () => clearInterval(interval);
  };

  return (
    <div>
      <ConfirmationDialog
        open={stationError}
        dialogueConfig={stationErrorDialog()}
        handleClose={() => {}}
        handleDialogueCancel={() => {}}
        handleDialogueSubmit={() => {}}
        hideButton={true}
      />
      <ConfirmationDialog
        open={paymentPrepareDialog}
        dialogueConfig={paymentConfirmDialogueConfig()}
        handleClose={() => {}}
        handleDialogueCancel={() => {}}
        handleDialogueSubmit={() => {}}
        hideButton={true}
      />

      <ConfirmationDialog
        open={transactionError}
        dialogueConfig={transactionErrorConfig()}
        handleClose={() => {}}
        handleDialogueCancel={() => {}}
        handleDialogueSubmit={() => {
          setTransactionError(false);
        }}
        hideButton={false}
        hideCancelButton={true}
      />
      <ConfirmationDialog
        open={durationDialog}
        dialogueConfig={chargeDurationConfig(authorize.authoriseResponse?.data?.balanceTime)}
        handleClose={() => {}}
        handleDialogueCancel={() => {}}
        handleDialogueSubmit={() => {
          setDurationDialog(false);
        }}
        hideButton={false}
        hideCancelButton={true}
      />

      <ConfirmationDialog
        open={connectorErrorDialog}
        dialogueConfig={connectorErrorDialogueConfig()}
        handleClose={() => {}}
        handleDialogueCancel={() => {}}
        handleDialogueSubmit={() => {
          setConnectorErrorDialog(false);
        }}
        hideButton={true}
      />

      <ConfirmationDialog
        open={connectorInUseDialog}
        dialogueConfig={connectorInUseDialogueConfig()}
        handleClose={() => {}}
        handleDialogueCancel={() => {}}
        handleDialogueSubmit={() => {
          setConnectorInUseDialog(false);
        }}
        hideButton={true}
      />

      <InstructionsDialogComponent
        instructionDialog={instructionDialog}
        setInstructionDialog={(e) => setInstructionDialog(e)}
      />
      {!isError && (
        <>
          {!isEmpty(authorize.stationDetails.data) && (
            <div style={{ textAlign: "center", marginTop: is768 ? "12%" : "4%" }}>
              <>
                {isEmpty(stationLogo) && (
                  <img
                    src={
                      "https://emaestro-svp-cms.s3.amazonaws.com/assets/49892392-46b6-474a-8bbd-70c0a1579e21.jpg"
                    }
                    style={is768 ? { width: "50%" } : { width: "20%" }}
                    alt=""
                  />
                )}
                {!isEmpty(stationLogo) && (
                  <>
                    <img
                      src={stationLogo}
                      style={is768 ? { width: "17%" } : { width: "6%" }}
                      alt=""
                    />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        sx={
                          is768
                            ? {
                                fontSize: "10px",
                                paddingTop: "2px",
                                paddingRight: "4px",
                              }
                            : { fontSize: "12px", paddingTop: "3px", paddingRight: "4px" }
                        }
                      >
                        Powered by
                      </Typography>
                      <img
                        src={
                          "https://emaestro-svp-cms.s3.amazonaws.com/assets/49892392-46b6-474a-8bbd-70c0a1579e21.jpg"
                        }
                        style={is768 ? { width: "15%" } : { width: "6%" }}
                        alt=""
                      />
                    </div>
                  </>
                )}
                <Typography
                  sx={
                    is768
                      ? {
                          fontSize: "1.3rem",
                          fontWeight: 600,
                        }
                      : { fontSize: "1.5rem", fontWeight: 600 }
                  }
                >
                  Energy MÆstro Hospitality Suite
                </Typography>
              </>
              <div className="row">
                <div className="col-4">
                  <Typography
                    sx={
                      is768
                        ? { fontSize: "1rem", fontWeight: 600 }
                        : { fontSize: "1.25rem", fontWeight: 600 }
                    }
                  >
                    {authorize.stationDetails?.data?.name}
                  </Typography>
                </div>
              </div>
            </div>
          )}
          {!isAuthenticate && !isEmpty(authorize.stationDetails.data) ? (
            <UserPreferenceContainer
              authorize={authorize}
              emailError={emailError}
              setEmailError={setEmailError}
              makeSessionCall={makeSessionCall}
              setPaymentPrepareDialog={setPaymentPrepareDialog}
              setInstructionDialog={setInstructionDialog}
            />
          ) : isAuthenticate ? (
            <DashboardContainer authorize={authorize} />
          ) : null}
        </>
      )}
      {isError && <AppErrorComponent />}
    </div>
  );
}

export default HomeComponent;
